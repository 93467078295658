<template>
  <v-container fill-height style="align-items: flex-start !important">
    <v-content>
      <h1 mb-2>{{title}}</h1>
      <div class="rowContent" v-html="description"></div>
      <v-layout v-for="(row,i) in contentRows" :key="i" column :class="{'mt-5':i==0,'mt-1':row.isCollapsible && i!=0}">
        <v-flex v-if="row.isCollapsible">
          <v-toolbar dark color="primary" @click="toggleRow(i)" style="cursor:pointer;">
            <v-side-icon>
              <v-icon right dark>{{isRowExpanded(i)?'remove':'add'}}</v-icon>
            </v-side-icon>
            <v-toolbar-title class="white--text expander-title">{{row.collapsedText}}</v-toolbar-title>
          </v-toolbar>
        </v-flex>
        <v-flex :class="{'rowContent':true,'rowContent-expando':row.isCollapsible}" v-if="!row.isCollapsible || (row.isCollapsible && isRowExpanded(i))">
          <v-layout :column="checkRowPosition(row,'column')" :row="checkRowPosition(row,'row')" wrap>
            <v-flex v-if="row.hasImage && !checkRowPosition(row,'reverse')" :md4="checkRowPosition(row,'row')">
              <img class="imageCenter" :style="getImageStyle(row)" :src="getSrc(row)" />
            </v-flex>
            <v-flex :md7="checkRowPosition(row,'row')" :offset-md1="checkRowPosition(row,'row') && !checkRowPosition(row,'reverse')"><section v-html="row.value"></section></v-flex>
            <v-flex v-if="row.hasImage && checkRowPosition(row,'reverse')" :md4="checkRowPosition(row,'row')" :offset-md1="checkRowPosition(row,'row') && checkRowPosition(row,'reverse')">
              <img class="imageCenter" :style="getImageStyle(row)" :src="getSrc(row)" />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout v-if="tags.length>0" row wrap justify-end>
        <v-chip class="white--text" color="accent darken-2" v-for="(tag,i) in tags" :key="i">{{getTagDisplay(tag)}}</v-chip>
      </v-layout>
    </v-content>
  </v-container>
</template>
<script>
  import eventBus from '../../utility/eventbus';
  export default {
    props: ['isPreview','data'],
    data: () => ({
      articleData: {},
      title: '',
      description: '',
      tags:[],
      contentRows: [{value:'',hasImage:false,image:'',imageName:'',imageLocation:'',isCollapsible:false}],
      expandedRows: []
    }),
    mounted() {
      let vueModel = this;
      this.tags.splice(0,1);
      if(this.data) vueModel.articleData = this.data;
      eventBus.listen(eventBus.events.articleLoad,function(article){
        vueModel.articleData = article;
      })
    },
    watch: {
      articleData: {
        handler() {
          if(this.articleData && this.articleData.info) {
            this.title=this.articleData.info.pageTitle;
            this.description = this.articleData.info.pageDescription;
            let localTags = this.articleData.info.tags || [];
            this.tags = [];
            for(var i=0;i<localTags.length;i++) {
              this.tags.push(localTags[i].name);
            }
          }
          if(this.articleData && this.articleData.body) {
            this.contentRows = this.articleData.body.rows;
          }
        },
        deep: true
      },
      contentRows: {
        handler: function (after, before) {
          // Return the object that changed
          let changed = after.filter( function( p, idx ) {
            return Object.keys(p).some( function( prop ) {
              if(!before || !before[idx]) return true;
              return p[prop] !== before[idx][prop];
            })
          })
        },
        deep: true
      }
    },
    computed: { },
    methods: {
      getSrc(rowItem) {
        if(this.isPreview && rowItem.image && rowItem.imageType) {
          return window.URL.createObjectURL(rowItem.image);
        }
        if(this.isPreview && rowItem.imageBase64String && rowItem.imageType) {
          return `data:${rowItem.imageType};base64, ${rowItem.imageBase64String}`
        }
        if(!this.isPreview && rowItem.imageBase64String && rowItem.imageType){
          return `data:${rowItem.imageType};base64, ${rowItem.imageBase64String}`
        }

      },
      getImageStyle(rowItem) {
        let styleObject = {};//{'width':'100%','height':'auto'};
        if (rowItem.imagePosition == 'l' || rowItem.imagePosition == 'r') {
          styleObject['width'] = '120%';
          styleObject['height'] = 'auto';
        }
        //width:100%;height:auto;
        // if(rowItem.customSize) {
        //   styleObject['max-height'] = rowItem.imageMaxHeight;
        //   styleObject['max-width'] = rowItem.imageMaxWidth;
        // }
        return styleObject;
      },
      checkRowPosition(rowItem,type) {
        switch(type.toLowerCase()) {
          case 'column':
            return rowItem.imagePosition == 'a' || rowItem.imagePosition == 'b';
          case 'row':
            return rowItem.imagePosition == 'l' || rowItem.imagePosition == 'r';
          case 'reverse':
            return rowItem.imagePosition == 'b' || rowItem.imagePosition == 'r';
        }
      },
      getTagDisplay(tag) {
        if(typeof tag == 'object') return tag.name;
        return tag;
      },
      isRowExpanded(index) {
        return this.expandedRows.indexOf(index) >= 0;
      },
      toggleRow(index) {
        const indexOfRowInExpandedRowsArray = this.expandedRows.indexOf(index);
        if(indexOfRowInExpandedRowsArray>=0) this.expandedRows.splice(indexOfRowInExpandedRowsArray,1);
        else this.expandedRows.push(index);
      }
    }
  }
</script>
<style scoped>
nav.v-toolbar >>> div.v-toolbar__content:hover {
  background-color: #153D54;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}
h1 {
  font-size: 37px;
  line-height: 50px;
  font-weight: 300;
  padding:0;
}
.expander-title {
  font-weight:700;
  font-size: large;
}
.rowContent {
  margin: 20px 0 10px;
}
.rowContent >>> p {
  margin-bottom: 0;
}
.rowContent >>> h1,
.rowContent >>> h2,
.rowContent >>> h3,
.rowContent >>> h4,
.rowContent >>> h5 {
  padding: 12px 0 7px;
}
.rowContent-expando {
  margin-left: 24px;
  margin-right: 24px;
}
  .imageCenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
</style>

