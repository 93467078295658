
<template>
  <v-app>
    <c-header :page="page"></c-header>
    <c-article :article-data="articleData"></c-article>
    <c-footer></c-footer>
  </v-app>
</template>

<script>
import logger from '../utility/logger';
import eventBus from '../utility/eventbus';
import HeaderComponent from '../components/header/header.vue'
import FooterComponent from '../components/footer/footer.vue'
import ArticleComponent from '../components/structure/article.vue'

export default {
  name: 'app-content-article',
  components: {
      'c-header':HeaderComponent,
      'c-footer':FooterComponent,
      'c-article':ArticleComponent
  },
  data(){
    return {
      pageName: 'article',
      page: ''
    }
  },
  mounted() {
    eventBus.emit(eventBus.events.loader,true);
    const vueModel = this;
    const articlePath = window.location.pathname;
    //if ( === 'information')
    this.page = articlePath.split('/')[1];
    fetch(`/api/content/articles?articleRoute=${articlePath}`, {
      method: 'GET',
      mode: 'cors',
      cache:'no-cache',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
    }).then(function(response){
      logger.log('content/articles/1','response complete');
      if(response.ok) {
        return response.json();
      } else {
        logger.log('content/articles/1','error calling for content',true);
        throw new Error('Request to get content failed');
      }
    }).then(function(jsonData){
      eventBus.emit(eventBus.events.loader,false);
      eventBus.emit(eventBus.events.articleLoad,jsonData.article);
    }).catch(function(){
      eventBus.emit(eventBus.events.loader,false);
    });
  }
}
</script>
